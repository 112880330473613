import { ComponentType, useEffect, useState } from 'react'
import type { ISponsorBanner } from '../SponsorBanner'
import type { IPicture } from '../Picture'
import type { IOutdatedBanner } from '../OutdatedBanner'
import type { IArticleBanner } from '../../organisms/ArticleBanner'

import { useContext } from 'react'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'
import { Bookmark } from '../../atoms/Bookmark/Bookmark'
import { TF1InfoLogo } from '../../atoms/Icon/TF1InfoLogo'
import { PictureElementList } from '../../atoms/PictureElementList'
import { MenuBurgerButton } from '../../atoms/MenuBurgerButton'
import { NotificationsButton } from '../../atoms/NotificationsButton'
import { SVGIcon } from '../../atoms/SVGIcon'
import { UserButton } from '../../atoms/UserButton'
import { useTMSHitForHeader } from '../../../hook/useTMSClickHit'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { PAGE_TYPE_CONTENT } from '../../../constants/page'
import { mixins, theme } from '../../../styles'
import { Share } from '../Share'

const ArticleBanner: ComponentType<IArticleBanner> = dynamic(function ArticleBanner() {
  return import('../../organisms/ArticleBanner').then((module) => module.ArticleBanner)
})

export interface IHeaderPage {
  eventLogo?: {
    pictures: IPicture
    link: string
  }
  outdatedBanner?: IOutdatedBanner
  sponsor?: ISponsorBanner
  topic?: {
    color: string
  }
  wordings?: {
    login?: string
    direct?: {
      title?: string
      link?: string
    }
  }
}

export function HeaderPage({
  eventLogo,
  outdatedBanner,
  topic,
  wordings,
}: IHeaderPage): JSX.Element {
  const [isReadingMode, setIsReadingMode] = useState(false)
  const { headerHit } = useTMSHitForHeader()
  const { type } = useContext(ApiResponseContext)
  const { hit } = useContext(TagCommanderContext)
  const isAmp = useAmp()
  const isContent = type === PAGE_TYPE_CONTENT

  function handleOnclick() {
    hit(
      {
        screen_clickableElementName: `header_directs`,
      },
      { isClickEvent: true },
    )
  }

  const { direct, login } = wordings || {}

  const getIntersectionObserver = (): IntersectionObserver =>
    new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries

        setIsReadingMode(entry.intersectionRatio === 0)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1.0],
      },
    )

  useEffect(() => {
    if (isContent) {
      const bodyHeaderComponent = document.querySelector("[data-module='body-header']")
      if (bodyHeaderComponent) {
        const intersectionObserver = getIntersectionObserver()
        intersectionObserver.observe(bodyHeaderComponent)

        return () => {
          intersectionObserver.disconnect()
        }
      }
    }
  }, [])

  const backArrow = (
    <svg
      className="Header__BackArrow flex items-center"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.0398 12.1133H5.0398"
        stroke={theme.cssVars.white}
        strokeWidth="2.91667"
        strokeLinejoin="bevel"
      />
      <path
        d="M12.0398 5.11328L5.03979 12.1133L12.0398 19.1133"
        stroke={theme.cssVars.white}
        strokeWidth="2.91667"
        strokeLinejoin="bevel"
      />
    </svg>
  )

  return (
    <>
      <header
        role="banner"
        aria-label="En-tête principale"
        className={`Header${isReadingMode ? ' Header__ReadingMode' : ''}`}
      >
        <div className="Header__Container flex items-center justify-between">
          <div className="flex items-center">
            <a
              href="/"
              className="Header__BackArrow"
              onClick={() => headerHit('fleche-retour')}
              data-testid="test-arrow"
            >
              {backArrow}
            </a>
            <div className="Header__Logo flex flex-column justify-center items-center">
              <a
                className="flex items-center"
                href="/"
                onClick={() => headerHit('logo')}
                title="Tf1 Info - Retour à l'accueil"
              >
                <TF1InfoLogo size={180} />
              </a>
              {eventLogo && (
                <a href={eventLogo.link} className="Header__EventLogo">
                  <PictureElementList {...eventLogo.pictures} layout="intrinsic" />
                </a>
              )}
            </div>
          </div>
          <div className="Header__Right flex items-center">
            {isContent ? (
              <>
                <Share
                  className="Header__Share"
                  size="24px"
                  primaryColor={theme.cssVars.white}
                  tmsId="header_bouton_partage"
                />
                <Bookmark className="Header__Bookmark" />
              </>
            ) : null}
            <UserButton unloggedWording={login} />
            {direct?.title && direct?.link ? (
              <a
                href={direct.link}
                onClick={handleOnclick}
                className="Header__Direct flex items-center justify-center"
              >
                <SVGIcon name="play-direct" size={20} primaryColor={theme.cssVars.white} />
                <span>{direct.title}</span>
              </a>
            ) : null}
            {!isAmp && <NotificationsButton />}
            <MenuBurgerButton />
          </div>
        </div>
      </header>
      {outdatedBanner ? <ArticleBanner outdatedBanner={outdatedBanner} /> : null}
      {isContent ? <div className="Header__Separator width-100" /> : null}

      <style jsx>{`
        .Header__Separator {
          background-color: ${topic?.color ?? theme.cssVars.focusBlue};
        }
      `}</style>
      <style jsx>{`
        .Header {
          background: ${theme.cssVars.deepBlue};
          position: sticky;
          top: 0;
          z-index: 50;
        }

        .Header__Container {
          padding: 7px 16px;
          z-index: 45;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Header__Container {
            margin: auto;
            padding: 7px ${theme.block.marginLR}px;
            max-width: ${theme.layout.maxWidth}px;
          }
        }

        .Header__Logo {
          margin-left: 0;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Header__Logo {
            flex-direction: row;
            margin: 0 auto 0 0;
          }
        }

        .Header__EventLogo {
          position: relative;
          display: block;
          overflow: hidden;
          height: 60px;
          min-width: 80px;
        }

        .Header__EventLogo :global(img),
        .Header__EventLogo :global(amp-img),
        .Header__EventLogo :global(svg) {
          height: 60px;
        }

        .Header__Direct {
          cursor: pointer;
          gap: 3px;
          height: 32px;
          padding: 6px 8px;
          border-radius: 24px;
          background-color: ${theme.cssVars.red};

          color: ${theme.cssVars.white};
          font-weight: 900;
          font-size: 14px;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .Header__Direct span {
          height: 16px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Header__Direct {
            gap: 4px;
            height: 32px;
            padding: 6px 15px;

            font-size: 15px;
          }

          .Header__Direct span {
            height: 17px;
          }
        }

        .Header__Separator {
          height: 4px;
        }

        .Header__Right {
          gap: 20px;
        }

        // Hide direct, share, bookmark, Account and notification buttons on mobile/tablet
        .Header__Direct,
        .Header
          :global(:where(.Header__Share, .Header__Bookmark, .UserButton, .NotificationsButton)) {
          display: none;
        }

        // Display Direct, Account and notification buttons on desktop
        @media ${mixins.mediaQuery.tablet} {
          .Header__Direct,
          .Header :global(.UserButton),
          .Header :global(.NotificationsButton) {
            display: flex;
          }
        }

        .Header__Right :global(:where(.Header__Share, .Header__Bookmark)) {
          cursor: pointer;
          background-color: transparent;
          border: none;
        }

        // After scrolling, display Bookmark, Share and Account buttons
        .Header__ReadingMode :global(:where(.Header__Share, .Header__Bookmark, .UserButton)) {
          display: flex;
        }

        // Only hide Direct, Menu and notification buttons on mobile after scrolling
        @media (max-width: ${theme.breakpoints.md}px) {
          .Header__ReadingMode
            :global(:where(.Header__Direct, .NotificationsButton, .MenuBurgerButton)) {
            display: none;
          }
        }
      `}</style>
    </>
  )
}
