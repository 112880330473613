import type { ComponentType } from 'react'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'

const UserButtonAmp: ComponentType<IUserButton> = dynamic(() =>
  import('./UserButtonAmp').then((module) => module.UserButtonAmp),
)

const UserButtonStandard: ComponentType<IUserButton> = dynamic(() =>
  import('./UserButtonStandard').then((module) => module.UserButtonStandard),
)

export interface IUserButton {
  unloggedWording: string
}

export function UserButton({ unloggedWording }: IUserButton): JSX.Element {
  const isAmp = useAmp()

  if (isAmp) {
    return <UserButtonAmp unloggedWording={unloggedWording} />
  }

  return <UserButtonStandard unloggedWording={unloggedWording} />
}
