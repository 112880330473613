import { useContext, useEffect, useState } from 'react'
import { NOTIFICATION_ID } from '../../../constants/components'
import { NotificationsContext } from '../../../contexts/NotificationsContext'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { theme } from '../../../styles'

export function NotificationsButton(): JSX.Element {
  const { hit } = useContext(TagCommanderContext)
  const { notifications, lastSeenTime } = useContext(NotificationsContext)
  const [unseenNotificationsNumber, setUnseenNotificationsNumber] = useState(0)

  const hitTms = () => {
    hit(
      {
        screen_clickableElementName: `header_notification_${unseenNotificationsNumber}`,
      },
      {
        isClickEvent: true,
      },
    )
  }

  useEffect(() => {
    setUnseenNotificationsNumber(
      notifications.filter((notification) => {
        return lastSeenTime ? new Date(notification.date) > new Date(lastSeenTime) : true
      })?.length,
    )
  }, [lastSeenTime, notifications])

  return (
    <>
      <label
        className="NotificationsButton flex justify-center items-center"
        htmlFor={NOTIFICATION_ID}
        onClick={hitTms}
      >
        <span
          className={`NotificationsButton__Indicator${
            unseenNotificationsNumber ? ' NotificationsButton__Indicator--active' : ''
          }`}
          data-length={`${unseenNotificationsNumber}`.length > 2}
        >
          {unseenNotificationsNumber}
        </span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.40481 7.82943C7.08102 3.99335 10.7381 1.43193 14.5731 2.10833C18.408 2.78473 20.9687 6.44283 20.2925 10.2789L19.5094 14.7216L20.2657 19.8232L3.16661 16.8073L5.62167 12.2721L6.40481 7.82943ZM14.1649 4.42361C11.6083 3.97267 9.17024 5.68029 8.71943 8.23768L7.87005 13.0561L6.7861 15.0585L17.4624 16.9415L17.1285 14.6891L17.9779 9.87066C18.4287 7.31328 16.7216 4.87455 14.1649 4.42361Z"
            fill={theme.cssVars.white}
          />
          <path
            d="M5.05461 7.59128C4.57001 10.3404 3.58796 12.9779 2.15673 15.3744L2.00884 15.622L1 15.0191L1.14788 14.7715C2.50579 12.4978 3.43753 9.99541 3.8973 7.38716L5.05461 7.59128Z"
            fill={theme.cssVars.white}
          />
          <path
            d="M21.7046 18.8222C21.1791 16.0806 21.1581 13.2661 21.6427 10.517L22.8 10.7212C22.3402 13.3294 22.3601 15.9997 22.8587 18.6008L22.913 18.8841L21.7589 19.1054L21.7046 18.8222Z"
            fill={theme.cssVars.white}
          />
          <path
            d="M11.308 20.6306C10.6689 20.5178 10.2421 19.9081 10.3548 19.2688L8.04017 18.8605C7.70207 20.7786 8.98241 22.6076 10.8999 22.9458C12.8174 23.284 14.6459 22.0033 14.984 20.0853L12.6694 19.677C12.5567 20.3164 11.9472 20.7433 11.308 20.6306Z"
            fill={theme.cssVars.white}
          />
        </svg>
      </label>

      <style jsx>{`
        .NotificationsButton {
          position: relative;
          width: 32px;
          height: 32px;
          cursor: pointer;
        }

        .NotificationsButton__Indicator {
          display: none;
          position: absolute;
          top: -3px;
          right: -2px;
          height: 17px;
          width: 17px;
          background-color: ${theme.cssVars.red};
          border-radius: 50%;
          color: ${theme.cssVars.white};
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          padding-top: 1px;
          z-index: 1;
        }

        .NotificationsButton__Indicator--active {
          display: block;
        }

        .NotificationsButton__Indicator--active[data-length='true'] {
          font-size: 11px;
          padding-top: 2px;
        }
      `}</style>
    </>
  )
}
